import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import TrainingGround from "../views/charts/TrainingGround";
import Homepage from "../views/custom-auth/Hompage";
import CRMAnalysis from "../views/charts/CRMAnalysis";
import Whats from "../views/apps/chat/Whats";
import Predictions from "../views/charts/Predictions";
import NewPage from "../views/custom-auth/NewLandingPage";

const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
const Minimal = Loadable(lazy(() => import("../views/dashboards/Minimal")));
const TreeChart = Loadable(
  lazy(() => import("../components/dashboard/minimalDashboard/TreeChart"))
);
const Login = Loadable(lazy(() => import("../views/custom-auth/Login")));
const LandingPage = Loadable(
  lazy(() => import("../views/custom-auth/LandingPage"))
);
const Chat = Loadable(lazy(() => import("../views/apps/chat/Chat")));
const ApexCharts = Loadable(lazy(() => import("../views/charts/ApexCharts")));
const ChartJs = Loadable(lazy(() => import("../views/charts/ChartJs")));

const ThemeRoutes = [
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/", element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "homepage", element: <LandingPage /> },
      { path: "home", element: <Homepage /> },
    ],
  },
  {
    path: "dashboards",
    element: <FullLayout />,
    children: [
      { path: "production", name: "Production", element: <Minimal /> },
      { path: "operations", name: "ChartJs", element: <ChartJs /> },
      { path: "chat", name: "Chat", element: <Chat /> },
      { path: "apex", name: "ApexCharts", element: <ApexCharts /> },
      { path: "analytics", name: "Analytics", element: <TreeChart /> },
      {
        path: "trainingGround",
        name: "Training Ground",
        element: <TrainingGround />,
      },
      { path: "CRMAnalytics", name: "CRM Analysis", element: <CRMAnalysis /> },
      { path: "whatsAppChat", name: "Whatsapp", element: <Whats /> },
      { path: "predictions", name: "Predictions", element: <Predictions /> },
      { path: "newlandingpage", element: <NewPage /> },
    ],
  },
  { path: "*", element: <Navigate to="/login" /> },
];

export default ThemeRoutes;
